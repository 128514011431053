import Vue from "vue";
import 'vant/lib/index.css';

import { 
    Button,
    Swipe,
    SwipeItem,
    Image as VanImage,
    Cell,
    Icon,
    Tabbar,
    TabbarItem,
    Card,
    Stepper,
    SubmitBar,
    Tab,
    Tabs,
    GoodsAction,
    GoodsActionIcon,
    GoodsActionButton,
    Checkbox,
    CheckboxGroup,
    Popup,
    Search,
    Tag,
    Field,
    RadioGroup,
    Radio,
    Dialog,
    Form,
    List,
    PullRefresh,
    Toast,
    CountDown,
    Step,
    Steps,
    Empty,
    TreeSelect,
    Lazyload,
    Col,
    Row,
    Loading,
    CellGroup,
    NoticeBar,
    AddressList,
    AddressEdit,
    ContactCard,
	Badge,
    Area,
    Overlay,
    Picker,
    Skeleton
} from 'vant';

Vue.use(Button)
    .use(Swipe)
    .use(SwipeItem)
    .use(VanImage)
    .use(Cell)
    .use(Icon)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Card)
    .use(Stepper)
    .use(SubmitBar)
    .use(Tab)
    .use(Tabs)
    .use(GoodsAction)
    .use(GoodsActionIcon)
    .use(GoodsActionButton)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Popup)
    .use(Search)
    .use(Tag)
    .use(Form)
    .use(Field)
    .use(RadioGroup)
    .use(Radio)
    .use(Dialog)
    .use(List)
    .use(PullRefresh)
    .use(Toast)
    .use(CountDown)
    .use(Step)
    .use(Steps)
    .use(Empty)
    .use(TreeSelect)
    .use(Lazyload)
    .use(Col)
    .use(Row)
    .use(Loading)
    .use(CellGroup)
    .use(NoticeBar)
    .use(AddressList)
    .use(AddressEdit)
    .use(ContactCard)
	.use(Badge)
    .use(Overlay)
    .use(Area)
    .use(Picker )
    .use(Skeleton )

