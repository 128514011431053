import wx from 'weixin-js-sdk'
import axios from "@/http"
import qs from "qs"


async function wxShare(meta) {
    let isWeXin = navigator.userAgent.toLowerCase().indexOf("micromessenger") != -1
    if (!isWeXin) {
        return
    }
    let isShare = meta.isShare || false
    let query = qs.parse(window.location.search.split('?')[1])
    delete query.code
    //默认分享路径
    let defaultHref = window.location.origin + window.location.pathname + '?' + qs.stringify(query)
    //如果标题存在，代表自定义
    if (!isShare) {
        //如果过期或者缓存不存在，重新请求配置
        const res = await axios.get("/get_jssdk", {params: {page_url: window.location.href.split('#')[0]}})
        const shareDataObj = {
            title: meta.title ? meta.title : process.env.VUE_APP_SHARE_TITLE,
            desc: meta.desc ? meta.desc : process.env.VUE_APP_SHARE_DESC,
            link: meta.link ? meta.link : process.env.VUE_APP_SHARE_LINK + '/home' + '?w=s',
            imgUrl: meta.imgUrl ? meta.imgUrl : "https://keyang2.tuzuu.com/uuo/088b12a82b8758152f979eff4ac65a8a.png",
        }
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.jssdk.appId, // 必填，公众号的唯一标识
            timestamp: res.data.jssdk.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.jssdk.nonceStr, // 必填，生成签名的随机串
            signature: res.data.jssdk.signature,// 必填，签名
            jsApiList: res.data.jssdk.jsApiList, // 必填，需要使用的JS接口列表
            openTagList: ['wx-open-launch-weapp']
        })
        let userid = localStorage.getItem("userid")
        let channel_id = localStorage.getItem("channel_id")
        wx.ready(function () {
            if (wx.updateAppMessageShareData) {
                wx.updateAppMessageShareData({
                    title: shareDataObj.title,
                    desc: shareDataObj.desc,
                    link: shareDataObj.link ? shareDataObj.link : process.env.VUE_APP_SHARE_LINK + `/home?userid=${userid}&channel_id=${channel_id}`,
                    imgUrl: shareDataObj.imgUrl,
                    success: function () {
                        console.log('分享朋友成功')
                    },
                    fail: function (error) {
                        console.log('分享朋友失败')
                    }
                })
            }
            if (wx.updateTimelineShareData) {
                wx.updateTimelineShareData({
                    title: shareDataObj.title,
                    link: shareDataObj.link ? shareDataObj.link : process.env.VUE_APP_SHARE_LINK + `/home?userid=${userid}&channel_id=${channel_id}`,
                    imgUrl: shareDataObj.imgUrl,
                    success: function () {
                        console.log('分享朋友圈')
                    },
                    fail: function () {
                        console.log('分享朋友圈失败')
                    }
                })
            }
            if (wx.onMenuShareAppMessage) {
                wx.onMenuShareAppMessage({
                    title: shareDataObj.title,
                    desc: shareDataObj.desc,
                    link: shareDataObj.link ? shareDataObj.link : process.env.VUE_APP_SHARE_LINK + `/home?userid=${userid}&channel_id=${channel_id}`,
                    imgUrl: shareDataObj.imgUrl,
                    success: function () {
                        console.log('旧版本分享朋友成功')
                    },
                    fail: function () {
                        // console.log('rnmsds=')
                        console.log('旧版本分享朋友失败')
                    }
                })
            }
            if (wx.onMenuShareTimeline) {
                wx.onMenuShareTimeline({
                    title: shareDataObj.title,
                    link: shareDataObj.link ? shareDataObj.link : process.env.VUE_APP_SHARE_LINK + `/home?userid=${userid}&channel_id=${channel_id}`,
                    imgUrl: shareDataObj.imgUrl,
                    success: function () {
                        console.log('旧版本分享朋友圈')
                    },
                    fail: function () {
                        console.log('旧版本分享朋友圈失败')
                    }
                })
            }
        })
    }
}

export default wxShare
