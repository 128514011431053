import Vue from "vue";
import VueRouter from "vue-router";
import share from "@/wechat/weChatTile";
import weChatAuth from "@/wechat/weChatAuth2";
import routes from "./routes";
import {J_TOKEN} from "../utils/Var";

if (!window.VueRouter) {
    Vue.use(VueRouter);
}

const router = new VueRouter({
    routes,
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return {x: 0, y: 0};
    },
});

// 授权验证
router.beforeEach((to, from, next) => {
    setTimeout(() => {
        share(to.meta)
    }, 1000)
    if (to.query.userid && to.query.channel_id) {
        localStorage.setItem("father_id", to.query.userid);
        localStorage.setItem("father_channel_id", to.query.channel_id);
    }
    // 是否为推广员
    if (to.matched.some(item => item.meta.isAgent) && localStorage.getItem("is_agent") != 1) {
        return next("/mycenter");
    }
    // 网页标题
    document.title = to.meta.title ? to.meta.title : '康来家园';
    // 验证授权
    if (to.meta.isAuth) {
        const token = localStorage.getItem(J_TOKEN);
        if (!token) {
            weChatAuth().then(() => {
                next()
            })
        } else {
            next()
        }
    } else {
        next()
    }
});

router.afterEach((to, from) => {
    setTimeout(() => {
        // 解决ios移动端 软键盘收起后，页面内容被顶上去，不下滑回原处
        const inputs = Array.from(document.querySelectorAll("input"));
        inputs.forEach(input => input.addEventListener("blur", window.scroll(0, 0)));
    }, 0);
});

export default router;
