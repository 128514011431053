import Vue from "vue";
import { Store, install } from "vuex";

import state from "./state"
import mutations from "./mutations"
import actions from "./actions"

install(Vue);

export default new Store({
    state,
    mutations,
    actions,
});
