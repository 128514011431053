import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./http";
import wx from "weixin-js-sdk";
import "@/assets/reset.css";

import "./vant";
import Video from 'video.js'
import 'video.js/dist/video-js.css';

Vue.prototype.$video = Video

if (module.hot) {		    // 是否开启了热更新
    module.hot.accept()		// 接受热更新
}

window.axios = axios;
window.wx = wx;
window._czc = []
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wx-open-launch-weapp']


// MtaH5.init({
//     "sid":'500735487', //必填，统计用的appid
//     "autoReport":0,//是否开启自动上报(1:init完成则上报一次,0:使用pgv方法才上报)
//     "senseHash":1, //hash锚点是否进入url统计
//     "senseQuery":1, //url参数是否进入url统计
//     "performanceMonitor":0,//是否开启性能监控
//     "ignoreParams":[] //开启url参数上报时，可忽略部分参数拼接上报
// })

// Vue.prototype.$share = share

function init() {
    const token = localStorage.getItem('token');
    token ? localStorage.removeItem('token') : '';
}
init();

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app");
