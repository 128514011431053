export default [
    {
        path: "/",
        redirect: "/home",
    },
    {
        // 绑定手机号
        path: "/bind_tel",
        component: () => import("../views/Login/BindTel"),
    },
    {
        // 首页
        path: "/home",
        component: () => import("../views/Home/index"),
        meta: {
            isAuth: true,
        },
    },
    {
        // 精品推荐
        path: "/recom",
        component: () => import("../views/Home/Recom"),
        meta: {
            title: "精品推荐",
            isAuth: true,
        },
    },
    {
        // 特价项目
        path: "/items",
        component: () => import("../views/Home/Items"),
        meta: {
            title: "特价项目",
            isAuth: true,
        },
    },
    {
        //更多评论
        path:'/comment/:id',
        component:() => import("../views/Order/Comment"),
        meta:{
            title:'更多评论',
            isAuth: true
        }
    },
    {
        // 下单页
        path: "/place_order/:id",
        component: () => import("../views/Order/PlaceOrder"),
        meta: {
            isAuth: true,
            isTel: true
        },
    },
    {
        // 支付成功
        path: "/pay_success",
        component: () => import("../views/Order/PaySuccess"),
        meta: {
            isAuth: true,
        },
    },
    {
        // 商品详情
        path: "/detail/:id",
        component: () => import("../views/Order/index"),
        meta: {
            isAuth: true,
            isTel: true,
            isShare:true
        },
    },
    {
        path:'/mycenter',
        component: () => import("../views/My/mycenter"),
        meta: {
            isAuth: true,
            title: "个人中心"
        },
    },

    {
        path:'/myagent',
        component: () => import("../views/Agent/myagent"),
        meta: {
            isAuth: true,
            title: "我的收益",
            isAgent: true
        },
    },
    {
        // 我的订单
        path: "/my",
        component: () => import("../views/My/index"),
        meta: {
            isAuth: true,
            isTel: true,
            title: "我的订单"
        },
    },
    {
        // 订单详情
        path: "/my/detail",
        component: () => import("../views/My/GoodsDetail"),
        meta: {
            isAuth: true,
            title:'订单详情'
        },
    },
    {
        path: "/my/code/success",
        component: () => import("../views/My/GoodsSuccess"),
        meta: {
            isAuth: true,
        },
    },
    {
        // 兑换兑换码
        path: "/exchange_code",
        component: () => import("../views/Code/ExchangeCode"),
        meta: {
            isAuth: true,
            isTel: true
        },
    },
    {
        // 课程
        path: "/course",
        component: () => import("../views/My/Course"),
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/app_code",
        component: () => import("../views/My/AppCode"),
        meta: {
            isAuth: true,
        },
    },
    {
        path:'/wait',
        component:() => import("../views/My/Wait"),
        meta: {
            isAuth: true,
        },
    },
    {
        // 404页面
        path: "*",
        component: () => import("../views/404"),
        meta: {
            title: "404",
        },
    },
    {
        //第三方的过渡页面
        path:'/three',
        component:() => import("../views/Code/ThreePage"),
        meta:{
            isAuth: true,
        }
    },
    {
        // 推广中心
        path:'/agent',
        component:() => import("../views/Agent/AgentIndex"),
        meta:{
            isAuth: true,
            title: "推广中心",
            isAgent: true,
            isTel:true
        }
    },
    {
        // 提现记录
        path:'/mycashout',
        component:() => import("../views/Agent/AgentMyCashOut"),
        meta:{
            isAuth: true,
            title: "我的提现记录",
            isAgent: true,
            isTel:true
        }
    }, {
        // 我的收益
        path:'/cashin',
        component:() => import("../views/Agent/AgentCashIn"),
        meta:{
            isAuth: true,
            title: "我的收益",
            isAgent: true,
            isTel:true
        }
    },
    {
        // 提现
        path:'/cashout',
        component:() => import("../views/Agent/AgentCashOut"),
        meta:{
            isAuth: true,
            title: "提现",
            isAgent: true,
            isTel:true
        }
    },
    {
        // 收货地址
        path:'/addrlist',
        component:() => import("../views/My/AddressList"),
        meta:{
            isAuth: true,
            title: "收货地址",
            isTel:true
        }
    },
    {
        // 编辑地址   
        path:'/addredit',
        component:() => import("../views/My/AddressEdit"),
        meta:{
            isAuth: true,
            title: "编辑地址",
            isTel:true
        }
    },
    {
        // 团购商品
        path:'/groupbuy',
        component:() => import("../views/Home/GroupBuy"),
        meta:{
            isAuth: true,
            title: "团购商品",
        }
    },
    {
        // 秒杀商品
        path:'/seckill',
        component:() => import("../views/Home/Seckill"),
        meta:{
            isAuth: true,
            title: "秒杀商品",
        }
    },
    {
        // K币明细
        path:'/kcoin',
        component:() => import("../views/My/KCoinDetail"),
        meta:{
            isAuth: true,
            title: "K币明细",
        }
    },
    {
        //细胞平台
        path:"/cells",
        component:() => import("../views/Cells/Cells"),
        meta:{
            title:'细胞质量查询'
        }
    },
    {
        //商品评价
        path:"/comments",
        component:() => import("../views/My/Comments"),
        meta:{
            isAuth: true,
            title:'商品评价'
        }
    },
    {
        //我的旅游团
        path: "/visit",
        component:() => import("../views/Tourism/Visit"),
        meta: {
            isAuth: true,
            title:'我的旅游团'
        }
    },
    {
        // 预约参观团
        path: "/subscribe",
        component: () => import("../views/Tourism/Subscribe"),
        meta: {
            isAuth: true,
            title:'预约参观团'
        }
    },
    {
        // 领取优惠券
        path: "/receive",
        component: () => import("../views/Common/ReceiveCoupons"),
        meta: {
            isAuth: true,
            title:'领取优惠券'
        }
    },
    {
        // 我的优惠券
        path: "/coupons",
        component: () => import("../views/My/Coupons"),
        meta: {
            isAuth: true,
            title:'我的优惠券'
        }
    },
    {
        //扫一扫
        path:"/my/express",
        component:() => import("../views/My/Express"),
        meta: {
            title: "一键呼叫快递小哥",
            isAuth: true,
        }
    },
    {
        //扫一扫
        path:"/my/expr_success",
        component:() => import("../views/My/ExpressSuccess"),
        meta: {
            title: "一键呼叫快递小哥",
            isAuth: true,
        }
    },
    {
        path:"/ydj",
        component:() => import("../views/Ydj/home/start"),
        meta: {
            title: "权益激活",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/ydj/active",
        component:() => import("../views/Ydj/home/active"),
        meta: {
            title: "权益激活",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/ydj/active-form",
        component:() => import("../views/Ydj/home/form"),
        meta: {
            title: "权益激活",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/ydj/person-center",
        component:() => import("../views/Ydj/Person/center"),
        meta: {
            title: "个人中心",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/ydj/person-interests",
        component:() => import("../views/Ydj/Person/interests"),
        meta: {
            title: "我的权益",
            isAuth: true,
            isShare:true
        }
    },
    {
        path: '/ydj/person-order',
        component:() => import("../views/Ydj/Person/order"),
        meta: {
            title: '我的预约',
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/new/ydj",
        component:() => import("../views/NewYdj/home/start"),
        meta: {
            title: "权益激活",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/new/ydj/active",
        component:() => import("../views/NewYdj/home/active"),
        meta: {
            title: "权益激活",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/new/ydj/active-form",
        component:() => import("../views/NewYdj/home/form"),
        meta: {
            title: "权益激活",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/new/ydj/person-interests",
        component:() => import("../views/NewYdj/Person/interests"),
        meta: {
            title: "我的权益",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/ydj/detail/:id",
        component:() => import("../views/NewYdj/detail/detail"),
        meta: {
            title: "商品详情",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/ydj/pay-order/:id",
        component:() => import("../views/NewYdj/detail/payOrder"),
        meta: {
            title: "商品详情",
            isAuth: true,
             isShare:true
        }
    },
    {
        path:"/ydj/pay-success/:id",
        component:() => import("../views/NewYdj/detail/paySuccess"),
        meta: {
            title: "支付成功",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/ydj/pay-ydj-success/:id",
        component:() => import("../views/NewYdj/detail/payYdjSuccess"),
        meta: {
            title: "支付成功",
            isAuth: true,
            isShare:true
        }
    },
    {
        path:"/ydj/user-list",
        component:() => import("../views/NewYdj/detail/userList"),
        meta: {
            title: "下级列表",
            isAuth: true,
        }
    },
    {
        path:"/ydj/share-order-list",
        component:() => import("../views/NewYdj/detail/userOrderList"),
        meta: {
            title: "分享订单信息",
            isAuth: true,
        }
    },
];