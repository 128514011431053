import axios from "axios";
import {Dialog} from "vant";
import {J_TOKEN} from "../utils/Var";
import weChatAuth from "../wechat/weChatAuth2";

const instance = axios.create({
    timeout: 10000,
    baseURL: (process.env.VUE_APP_SHARE_LINK || "http://test.tuzuu.com") + "/api/v1",
});
// 请求拦截器
instance.interceptors.request.use(request => {
    const token = localStorage.getItem(J_TOKEN);

    if (token && request.url.indexOf('http') == -1) {
        request.headers.Authorization = 'Bearer ' + token;
    }

    return request;
}, err => {
    return Promise.reject(err)
})

// 响应拦截器
instance.interceptors.response.use(res => {
    const {code, data, message,} = res.data
    if (code === 1) {
        return res.data
    } else if (code === 401) {
        // localStorage.removeItem(J_TOKEN);
        // weChatAuth().then(() => {
        //     location.reload();
        // })
    } else if (code === 5202) {
        Dialog.alert({
            message: res.data.message
        }).then(() => {
            location.href = '/bind_tel';
        }).catch(() => {
        })
        return data
    }
}, err => {
    if (err.response.status == 401) {
        localStorage.removeItem(J_TOKEN);
        weChatAuth().then(() => {
            location.reload();
        })
    } else {
        return Promise.reject(err);
    }
});

export default instance;