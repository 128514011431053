import axios from "@/http";
import {J_TOKEN} from "../utils/Var";
import qs from "qs"

let appid;
if (process.env.VUE_APP_RUN_ENV === "prod") {
    appid = "wx076e3f56030f2f82";
} else {
    appid = "wx616cd9c43d0e265e";
}
let locationUrl = window.location.href
let webUrl = locationUrl.split("?")[0]
export default function weChatAuth() {
    return new Promise((resolve, reject) => {
        let query = qs.parse(locationUrl.split('?')[1])
        console.log('----')
        console.log(query)
        if(query.code){
            axios.get("/wechat_login_by_code",{
                params:{
                    wx_code: query.code,
                    ...query
                }
            }).then((res) => {
                if (res?.code == 1) {
                    localStorage.setItem(J_TOKEN, res.data.token);
                    localStorage.setItem("userid", res.data.userid);
                    resolve();
                }else{
                    delete query.code
                    delete query.state
                    webUrl = webUrl + qs.stringify(query)
                    hrefLogin(webUrl)
                    return
                }
            })
        }else{
            hrefLogin(locationUrl)
        }
    })
}

//防止登录时，多次在url沙上添加code
function hrefLogin(redirectUrl){
    redirectUrl = encodeURIComponent(redirectUrl)
    const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    window.location.href =  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=${state}&connect_redirect=1#wechat_redirect`;
}
