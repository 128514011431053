<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    watch: {
      $route() {
        if (window._czc) {
          let location = window.location;
          let contentUrl = location.pathname + location.hash;
          let refererUrl = "/";
          window._czc.push(["_trackPageview", contentUrl, refererUrl]);
        }
      }
  },
  mounted () {
    // 友盟统计添加
    const script = document.createElement("script");
    script.src = "https://s4.cnzz.com/z_stat.php?id=1279656423&web_id=1279656423";
    script.language = "JavaScript";
    document.body.appendChild(script);
  },
};
</script>

<style>
#app {
    width: 100%;
    height: 100%;
}
</style>
